import React from 'react';
import './App.css';
import Header from "./components/Header/Header";
import Social from "./components/Social/Social";
import About from "./components/About/About";
import Project from "./components/Project/Project";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <html lang='en'>
        <head>
            <meta charSet="UTF-8"/>
            <title>Bruna Mello</title>
        </head>

        <body>
            <Header />
            <Social />
            <About />
            <Project />
            <Contact />
        <Footer />
        </body>

    </html>

);
}

export default App;
