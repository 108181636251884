import React from "react";
import './Contact.css'
import '../../App.css'

import CVImg from '../../assets/img/curriculum-vitae-svgrepo-com.svg';
import LinkedInImg from '../../assets/img/linkedin-dark.svg';
import GitHubImg from '../../assets/img/github-dark.svg';

export default function Contact() {
    return <section id="contact" className="contact sec_pad dynamicBg">
        <div className="main_container">
            <h2 className="heading heading_sec heading_sec_mb_med">
                <span className="heading_sec_main heading_sec_main_lt">Contact</span>
                <a href = "mailto: brunacrespomello@gmail.com" className="heading_sec_sub heading_sec_sub_lt">brunacrespomello@gmail.com</a>
            </h2>
            <div className="row">
                <div className="container">
                    <div className="justify_content_center">
                        <div>
                            <img src={LinkedInImg} className="contact_icon" alt='CvImg'/>
                            <br></br>
                            <a className="heading_sec_sub heading_sec_sub_lt" href="https://www.linkedin.com/in/brunacrespomello/">LinkedIn</a>
                        </div>
                        <div>
                            <img src={CVImg} className="contact_icon" alt='LinkedInImg'/>
                            <br></br>
                            <a className="heading_sec_sub heading_sec_sub_lt" href='../../assets/docs/BrunaMelloCS.pdf'>CV</a>
                        </div>
                        <div>
                            <img src={GitHubImg} className="contact_icon" alt='GitHubImg'/>
                            <br></br>
                            <a className="heading_sec_sub heading_sec_sub_lt" href="https://github.com/BrunaMello">GitHub</a><br></br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>
}
