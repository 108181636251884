import React from "react";
import './Project.css'
import '../../App.css'

import PanucciImg from '../../assets/img/panucci-home-screen.png';
import ToDoImg from '../../assets/img/todo-list-app.png';

export default function Project() {
    return <section id="projects" className="projects sec_pad">
        <div className="main_container">
            <h2 className="heading heading_sec heading_sec_mb_bg">
                <span className="heading_sec_main">Projects</span>
                <span className="heading_sec_sub">
          Here you will find some of the personal and clients projects that I
          created with each project containing its own case study
        </span>
            </h2>

            <div className="projects_content">

                <div className="projects_row">
                    <div className="projects_row_img_cont">
                        <img src={PanucciImg} alt="Software Screenshot" className="projects_row_img" loading="lazy"/>
                    </div>
                    <div className="projects_row_content">
                        <h3 className="projects_row_content_title">To Do List App</h3>
                        <p className="projects_row_content_desc">
                            Developed with Swift, this feature_rich application offers a seamless user experience with its clean and modern interface. SwiftTasks empowers you to effortlessly organize your tasks, set priorities, and manage deadlines, ensuring you stay on top of your commitments.
                        </p>
                        <a className="btn btn_med btn_theme dynamicBgClr" href="https://github.com/BrunaMello/mell-to-do-list" target="_blank" rel="noreferrer">GitHub Page</a>
                    </div>
                </div>

                <div className="projects_row">
                    <div className="projects_row_content">
                        <h3 className="projects_row_content_title">Panucci Ristorante</h3>
                        <p className="projects_row_content_desc">
                            Introducing the exquisite Ristorante App, crafted with Flutter, a remarkable mobile application that brings the culinary world to your fingertips. With an elegant and intuitive user interface, the app allows you to effortlessly discover, reserve, and order from your favorite eateries with just a few taps.
                        </p>
                        <a className="btn btn_med btn_theme dynamicBgClr" href="https://github.com/BrunaMello/alura_flutter-applying_contraints_and_responsive_layouts" target="_blank" rel="noreferrer">GitHub Page</a>
                    </div>
                    <div className="projects_row_img_cont">
                        <img src={ToDoImg} alt="Software Screenshot" className="projects_row_img" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
}

