import React from 'react';
import './Header.css';
import '../../App.css';

import profileImg from '../../assets/img/profile.png';
import ham_open from '../../assets/img/ham-menu.svg';
import ham_close from '../../assets/img/ham-menu-close.svg';

export default function Header() {

    return (
        <header className="header">
            <div className="header_content">
                <div className="header_logo_container">
                    <div className="header_logo_img_cont">
                        <img src={profileImg} alt="Bruna Mello Logo" className="header_logo_img"/>
                    </div>
                    <span className="header_logo_sub">Bruna Mello</span>
                </div>
                <div className="header_main">
                    <ul className="header_links">
                        <li className="header_link_wrapper">
                            <a href="./" className="header_link">Home</a>
                        </li>
                        <li className="header_link_wrapper">
                            <a href="./#about" className="header_link">About</a>
                        </li>
                        <li className="header_link_wrapper">
                            <a href="./#projects" className="header_link">Projects</a>
                        </li>
                        <li className="header_link_wrapper">
                            <a href="./#contact" className="header_link">Contact</a>
                        </li>
                    </ul>
                    <div className="header_main_ham_menu_cont">
                        <img src={ham_open} alt="hamburger menu" className="header_main_ham_menu"/>
                            <img src={ham_close} alt="hamburger menu close" className="header_main_ham_menu_close d_none"/>
                    </div>
                </div>
            </div>
            <div className="header_sm_menu">
                <div className="header_sm_menu_content">
                    <ul className="header_sm_menu_links">
                        <li className="header_sm_menu_link">
                            <a href="./"> Home </a>
                        </li>

                        <li className="header_sm_menu_link">
                            <a href="./#about"> About </a>
                        </li>

                        <li className="header_sm_menu_link">
                            <a href="./#projects"> Projects </a>
                        </li>

                        <li className="header_sm_menu_link">
                            <a href="./#contact"> Contact </a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
)
}

