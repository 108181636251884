import React from "react";
import "./Footer.css";
import "../../App.css";


import linkedin_dark from '../../assets/img/linkedin-ico.png';
import github_dark from '../../assets/img/github-ico.png';


export default function Footer() {

    var date = new Date().getFullYear();

    return <footer className="main_footer">
        <div className="main_container">
            <div className="main_footer_upper">
                <div className="main_footer_row main_footer_row_1">
                    <h2 className="heading heading_sm main_footer_heading_sm">
                        <span>Social</span>
                    </h2>
                    <div className="main_footer_social_cont">
                        <a target="_blank" rel="noreferrer" href="https://linkedin.com/in/brunacrespomello">
                            <img className="main_footer_icon" src={github_dark} alt="Bruna Mello Linkedin Profile"/>
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://github.com/brunamello">
                            <img className="main_footer_icon" src={linkedin_dark} alt="Bruna Mello Github Profile"/>
                        </a>
                    </div>
                </div>
                <div className="main_footer_row main_footer_row_2">
                    <h2 className="heading heading_sm text_lt">Bruna Mello</h2>
                    <p className="main_footer_short_desc">
                        A Mobile Developer focused in building the Apps and Web Applications that leads to the success of the overall product
                    </p>
                </div>
            </div>

            <div className="main_footer_lower">
                © {date}. Made by
                <a rel="noreferrer" target="_blank" href="https://brunacrespomello.com">Bruna Mello</a>
            </div>
        </div>
    </footer>
}
