import React from "react";
import './Social.css';
import '../../App.css';

import linkedin_dark from '../../assets/img/linkedin-dark.svg';
import github_dark from '../../assets/img/github-dark.svg';


export default function Social() {
    return <section className='home_hero'>
        <div className='home_hero_content'>
            <h1 className='heading_primary'>Hey, I'm Bruna Mello</h1>
            <div className='home_hero_info'>
                <p className='text_primary'>A Mobile Developer focused Web Developer building the Frontend of Websites
                    and Web Applications that leads to the success of the overall
                    product </p>
            </div>
            <div className='home_social_cta'>
                <a href='./#projects' className='btn btn_theme btn_bg '>Projects</a>
            </div>
        </div>
        <div className="home_hero_socials">
            <div className="home_hero_social">
                <a href="https://linkedin.com/in/brunacrespomello" className="home_hero_social_icon_link" rel="noreferrer" target="_blank">
                    <img src={linkedin_dark} alt="Bruna Mello Linkedin Profile" className="home_hero_social_icon"/>
                </a>
            </div>
            <div className="home_hero_social">
                <a href="https://github.com/brunamello" className="home_hero_social_icon_link" rel="noreferrer" target="_blank">
                    <img src={github_dark} alt="Bruna Mello Github Profile" className="home_hero_social_icon"/>
                </a>
            </div>
        </div>
        <div className="home_hero_mouse_scroll_cont">
            <div className="mouse"></div>
        </div>
    </section>
}
