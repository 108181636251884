import React from "react";
import '../../App.css';
import './About.css';

import GitImg from '../../assets/img/Git.svg';
import SwiftImg from '../../assets/img/swift.svg';
import FlutterImg from '../../assets/img/Flutter-Light.svg';
import DartImg from '../../assets/img/Dart-Light.svg';
import JavaImg from '../../assets/img/Java-Light.svg';
import AndroidImg from '../../assets/img/AndroidStudio-Light.svg';
import GithubImg from '../../assets/img/Github-Light.svg';
import JavaScriptImg from '../../assets/img/JavaScript.svg';
import ReactImg from '../../assets/img/React-Light.svg';
import FirebaseImg from '../../assets/img/Flutter-Light.svg';



export default function About() {
    return <section id="about" className="about sec_pad">
        <div className="main_container">
            <h2 className="heading heading_sec heading_sec_mb_med">
                <span className="heading_sec_main">About Me</span>
                <span className="heading_sec_sub"> Here you will find more information about me, what I do, and my current skills mostly in terms of programming and technology
        </span>
            </h2>
            <div className="about_content">
                <div className="about_content_main">
                    <h3 className="about_content_title">Get to know me!</h3>
                    <div className="about_content_details">
                        <p className="about_content_details_para">
                            I'm a <strong>Mobile Developer</strong> building
                            Apps and Web Applications that leads to the
                            success of the overall product. Check out some of my work in the
                            <strong>Projects</strong> section.
                        </p>
                        <p className="about_content_details_para">
                            I'm open to <strong>Job</strong> opportunities where I can
                            contribute, learn and grow. If you have a good opportunity that
                            matches my skills and experience then don't hesitate to
                            <strong>contact</strong> me.
                        </p>
                    </div>
                    <a href="./#contact" className="btn btn_med btn_theme dynamicBgClr">Contact</a>
                </div>
                <div className="about_content_skills">
                    <h3 className="about_content_title">My Skills</h3>
                    <div className="skills">
                        <div className="skills_skill">
                            <img className="img_skill" src={SwiftImg} alt="swift_logo"/>
                            </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={FlutterImg} alt="flutter_logo"/>
                        </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={DartImg} alt="dart_logo"/>
                        </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={JavaImg} alt="java_logo"/>
                            </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={AndroidImg} alt="android_logo"/>
                            </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={GitImg} alt="git_logo"/>
                        </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={GithubImg} alt="github_logo"/>
                        </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={JavaScriptImg} alt="javascript_logo"/>
                        </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={ReactImg} alt="react_logo"/>
                        </div>
                        <div className="skills_skill">
                            <img className="img_skill" src={FirebaseImg} alt="firebase_logo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
